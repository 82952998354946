/* styles.css */

/* Flexbox utilities */

.edit{
    max-width:700px;
    margin:auto;
}
.flex {
  display: flex;
}
.w-full{
    width:100%;
}
.flex-col {
  flex-direction: column;
}

/* Spacing utilities */
.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

/* Text utilities */
.text-lg {
  font-size: 1.125rem;
}

.text-xl {
  font-size: 1.25rem;
}

/* Background color utilities */
.bg-blue {
  background-color: blue;
}

.bg-green {
  background-color: green;
}

/* Text color utilities */
.text-red {
  color: red;
}
.p-15px{
    padding:15px;
}
.text-purple {
  color: purple;
}
.edit button{
    height:50px;
    cursor:pointer;
}
.edit input{
    height:50px;
    padding:0px 15px;
}
.edit textarea{
    height:50px;
    padding:10px 15px;
}
.edit img{
    
    max-height:350px;
    object-fit:contain;
    
}
.gap-1{
    gap:5px;
}
.gap-2{
    gap:10px;
}
.gap-3{
    gap:15px;
}
.gap-4{
    gap:20px;
}
.gap-5{
    gap:25px;
}

.min-h-150px{
    min-height:150px;
}